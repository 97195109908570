import ACTIONS from '../../actions';
import Api from '../../api';
import { prepareStatisticFunctional } from '../../utils/statistic';

let store = null;

class StaticsService {
  constructor() {
    store = this.getStore('Statics');
  }

  get = () => store.getState();

  setId = (id) => store.dispatch({
    type: ACTIONS.COMPANY.SETID,
    id,
  });

  subscribe = (callback) => store.subscribe(callback);

  // получить статистику
  getStatisticFunctional = async (accountId) => {
    try {
      const [statics, schemas] = await Promise.all([
        Api.Company.getStatisticFunctional(accountId),
        Api.Company.getSchemas(accountId),
      ]);

      const periods = statics && statics.Periods ? statics.Periods : [];
      const statisticFunctional = prepareStatisticFunctional(periods, schemas);
      store.dispatch({
        type: ACTIONS.COMPANY.UPDATE_STATISTIC_FUNCTIONAL,
        payload: { statisticFunctional, bonuses: statics.BonusesGiftsLoyalty },
      });
    } catch (err) {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_FUNCTIONAL, payload: [] });
    }
  };

  getStatisticComplaints = async (accountId) => {
    try {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: true });

      const complaints = await Api.Company.getStatisticComplaints({ account_id: accountId });

      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_COMPLAINTS, payload: complaints });
    } catch (err) {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_COMPLAINTS, payload: [] });
    }
  };

  createStatisticComplaint = () => store.dispatch({ type: ACTIONS.COMPANY.CREATE_STATISTIC_COMPLAINT });

  removeStatisticComplaint = (id) => store.dispatch({ type: ACTIONS.COMPANY.REMOVE_STATISTIC_COMPLAINT, payload: id });

  saveCreateStatisticComplaint = async (complaint) => {
    try {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: true });

      const complaints = await Api.Company.createStatisticComplaint(complaint);

      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_COMPLAINTS, payload: complaints });
    } catch (err) {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: false });
    }
  };

  saveUpdateStatisticComplaint = async (complaint) => {
    try {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: true });

      const complaints = await Api.Company.updateStatisticComplaint(complaint);

      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_COMPLAINTS, payload: complaints });
    } catch (err) {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: false });
    }
  };

  saveRemoveStatisticComplaint = async (complaintId) => {
    try {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: true });

      await Api.Company.removeStatisticComplaint({ id: complaintId });

      this.removeStatisticComplaint(complaintId);
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: false });
    } catch (err) {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS, payload: false });
    }
  };

  updateStatisticBonuses = async (accountId, dataBonuses) => {
    try {
      const updateStatisticBonuses = await Api.Company.updateStatisticBonuses(accountId, { text: dataBonuses });

      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_BONUSES, payload: updateStatisticBonuses.text });
    } catch (err) {
      store.dispatch({ type: ACTIONS.COMPANY.UPDATE_STATISTIC_BONUSES, payload: 'Ошибка получения данных' });
    }
  };
}

export default StaticsService;
