import Api from '../../api';
import {
  preparedRightsToFrontend, convertAvailableRightsToText, convertUnavailableRightsToText,
} from '../../utils/rigts';

class RightsService {
  getRightsDetails = (rights) => {
    const preparedRights = preparedRightsToFrontend(rights);
    const {
      buyAfterApprove, buyApprovalScheme, buyTravelPolicy, buyTripAccount,
    } = preparedRights;
    const availableRights = convertAvailableRightsToText(preparedRights);
    const unAvailableRights = convertUnavailableRightsToText(preparedRights);

    return {
      availableRights,
      unAvailableRights,
      buyAfterApprove,
      buyApprovalScheme,
      buyTravelPolicy,
      buyTripAccount,
      canLogin: rights.CanLogin,
    };
  };

  getEmployeeRights = async (accountId, employeeId) => {
    try {
      const employeeRights = await Api.Rights.getEmployeeRights(accountId, employeeId);
      const rights = await this.getRightsDetails(employeeRights);

      return { ...rights, ...employeeRights };
    } catch (e) {
      return null;
    }
  };
}

export default RightsService;
